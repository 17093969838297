import React, {useEffect} from 'react';

import {Debbie} from 'components/organisms/Debbie';
import {goBack} from 'lib/navigation/routes';

import {Footer} from './Footer';
import {Navbar} from './Navbar';

export const ErrorPage = ({
  title = 'Oh no, something went wrong!',
  subtitle = 'The team has been notified and is looking into this.',
  showNavbar = true,
}: {
  title?: string;
  subtitle?: string;
  showNavbar?: boolean;
}) => {
  useEffect(() => {
    document.title = 'Payble - Error';
  }, []);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-between">
      {showNavbar && <Navbar />}
      <div className="max-w-lg mx-auto px-2 flex flex-col px-4 py-5 sm:px-6 ">
        <Debbie title={title} message={subtitle} />
        <button
          type="button"
          onClick={goBack}
          className="mt-8 w-full text-center justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go back
        </button>
      </div>
      <Footer />
    </div>
  );
};
