import {z} from 'zod';
import {UnexpectedError} from '../errs';
import {SurchargeCalculator} from '../util/SurchargeCalculator';
import {SurchargeConfigSchema} from '../util/SurchargeConfig';

const ConsumerAppConfigSchema = z.object({
  stage: z.string(),
  biller: z.object({
    billerId: z.string().uuid(),
    name: z.string(),
    slug: z.string(),
  }),
  surcharges: SurchargeConfigSchema.optional(),
  region: z.enum(['AU', 'NZ']),

  isLive: z.boolean(),

  consumerAppHostname: z.string(),
  mobileNumberPlaceholder: z.string(),
  directDebitUserId: z.string().optional(),

  requirePaymentNotificationConsent: z.boolean(),

  authMethod: z.array(z.enum(['mobile', 'email'])).min(1),

  supportEmail: z.string(),
  support: z.object({
    email: z.string().optional(),
    address: z.string().optional(),
    phone: z.string().optional(),
    abn: z.string().optional(),
    url: z.string().optional(),
    supportWebsite: z.string().optional(),
    openingTimes: z.string().optional(),
    directDebitServiceAgreement: z.string().optional(),
    directDebitServicePaperForm: z.string().optional(),
    hardshipLink: z.string().optional(),
  }),
});

export class ConsumerAppConfig {
  static get schema() {
    return ConsumerAppConfigSchema.transform(
      data => new ConsumerAppConfig(data)
    ).or(z.instanceof(ConsumerAppConfig));
  }
  static maybeFromJSON(data: unknown) {
    try {
      return ConsumerAppConfig.fromJSON(
        data as z.input<typeof ConsumerAppConfigSchema>
      );
    } catch {
      return null;
    }
  }

  static fromJSON(data: z.input<typeof ConsumerAppConfigSchema>) {
    try {
      return new ConsumerAppConfig(ConsumerAppConfigSchema.parse(data));
    } catch (error) {
      throw UnexpectedError.wrap(error, undefined, {
        cause: 'Could not parse site config data.',
      });
    }
  }

  get authMethod() {
    return this.props.authMethod;
  }
  get requirePaymentNotificationConsent() {
    return this.props.requirePaymentNotificationConsent;
  }
  get directDebitUserId() {
    return this.props.directDebitUserId;
  }

  get mobileNumberPlaceholder() {
    return this.props.mobileNumberPlaceholder;
  }

  get supportEmail() {
    return this.props.supportEmail;
  }

  get region() {
    return this.props.region;
  }

  get biller() {
    return this.props.biller;
  }

  get consumerAppHostname() {
    return this.props.consumerAppHostname;
  }

  get isLive() {
    return this.props.isLive;
  }

  get support() {
    return this.props.support;
  }

  get surchargeCalculator() {
    return new SurchargeCalculator(this.props.surcharges ?? []);
  }

  get stage() {
    return this.props.stage;
  }

  toJSON(): z.output<typeof ConsumerAppConfigSchema> {
    return this.props;
  }

  constructor(private props: z.output<typeof ConsumerAppConfigSchema>) {}
}
