import React, {useEffect} from 'react';
import {XMarkIcon} from '@heroicons/react/20/solid';

import {getBillerSlugFromUrl} from 'lib/url';
import {AccountTypeInfo, BillerConfig, getBillerConfig} from 'payble-shared';
import {markDownToHtml} from 'lib/markDownToHtml';
import {useGetAccountTypeInfo} from 'features/setup/hooks/useGetAccountTypeInfo';
import {useSetupSearchParams} from 'features/setup/hooks/useSetupSearchParams';
import {Form, Formik} from 'formik';
import {LookupAccountTypeRadio} from 'features/setup/components/LookupAccountTypeRadio';
import {
  LookupAccountExternalIdInput,
  VerifyAccountForm,
} from 'features/setup/components/LookupAccountExternalIdInput';
import {LookupAccountSubmitButton} from 'features/setup/components/LookupAccountSubmitButton';
import {Loading} from 'payble-ui';

import {useInfringementNavigate} from 'features/infringement/hooks/useInfringementNavigate';
import {
  GetAccountByExternalIdResponse,
  useGetAccountFromSearchParams,
} from 'lib/account/useGetAccountFromSearchParams';
import {ConfirmLinkAccount} from 'features/setup/components/ConfirmLinkAccount';
import {useAnonymousPaymentParams} from '../hooks/useAnonymousPaymentParams';
import {useAnonymousPaymentNavigate} from '../hooks/useAnonymousPaymentNavigate';
import {Debbie} from 'components/organisms/Debbie';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

function accountCanNotSignupBecauseAccountOwesNothing({
  account,
  billerConfig,
}: {
  account?: GetAccountByExternalIdResponse | null;
  billerConfig: BillerConfig;
}) {
  if (!account) {
    return false;
  }

  return account.amountOwing <= 0 && !billerConfig.canPayZeroBalanceAccount;
}

function getErrorMessage({
  error,
  notFoundError,
  accountCanNotSignup,
}: {
  error?: string;
  notFoundError?: string;
  accountCanNotSignup: boolean;
}) {
  if (error) {
    return error;
  }

  if (notFoundError) {
    return notFoundError;
  }

  if (accountCanNotSignup) {
    return 'It looks like your account is already fully paid.';
  }

  return '';
}

type LookupAccountProps = {
  onSubmit: (values: {accountType: string; accountExternalId: string}) => void;
  supportedPaymentCategory: AccountTypeInfo['paymentCategory'];
  loading?: boolean;
  error?: string;
  requiresVerification?: boolean;
};

const LookupAccount: React.FC<LookupAccountProps> = ({
  loading,
  error,
  requiresVerification,
  onSubmit,
  supportedPaymentCategory,
}) => {
  const accountTypeInfo = useGetAccountTypeInfo();
  const {accountExternalId, accountType} = useSetupSearchParams();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);

  return (
    <Formik<{
      accountType: string;
      accountExternalId: string;
      verificationCode?: string;
    }>
      initialValues={{
        accountType,
        accountExternalId,
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="col-span-6 mt-4 mb-4 sm:col-span-3">
          <LookupAccountTypeRadio
            supportedPaymentCategory={supportedPaymentCategory}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <LookupAccountExternalIdInput
            accountTypeInfo={accountTypeInfo}
            disabled={!!loading}
          />
        </div>

        {requiresVerification && (
          <div className="col-span-6 sm:col-span-3">
            <VerifyAccountForm />
          </div>
        )}

        <div className="col-span-6 sm:col-span-3">
          {error && (
            <div className="flex items-center pt-2">
              <XMarkIcon className="w-4 h-4 text-red-500" />
              <span className="mt-1 ml-1 text-sm text-gray-400">{error}</span>
            </div>
          )}

          <LookupAccountSubmitButton loading={loading} cta="Lookup Account" />
        </div>
        {billerConfig.accountLookupAgreement.map((term, index) => (
          <p
            className="text-gray-600 [&>a]:underline pt-4"
            key={index}
            dangerouslySetInnerHTML={{
              __html: markDownToHtml(term, ['a']),
            }}
          />
        ))}
      </Form>
    </Formik>
  );
};

const SUPPORTS_PAYMENT_CATEGORY = 'notice';

export const LookupAccountPage = () => {
  const {
    loading: loadingAccount,
    data: account,
    error,
    requiresVerification,
  } = useGetAccountFromSearchParams({anonymous: true});

  const {lookingUpAnother, accountType, patch} = useAnonymousPaymentParams();

  const navigate = useAnonymousPaymentNavigate();
  const infringementNavigate = useInfringementNavigate();

  const billerConfig = useBillerConfig();

  useEffect(() => {
    if (accountType === 'infringements') {
      infringementNavigate('/confirm-payment', undefined, undefined, true);
    }
  }, [accountType]);

  const accountCanNotSignup = accountCanNotSignupBecauseAccountOwesNothing({
    account,
    billerConfig,
  });

  const loading = loadingAccount;

  const showLookupForm =
    lookingUpAnother || (!loading && !account) || accountCanNotSignup;

  const showConfirmLinking =
    !lookingUpAnother && !loading && account && !accountCanNotSignup;

  const externalIdLabel = account
    ? billerConfig.getExternalIdLabel(account.type)
    : 'account number';

  const onNext = async () => {
    navigate('/amount');
  };
  const notFoundError = !loading && account === null ? 'No account found' : '';

  return (
    <div className="relative h-full max-w-2xl px-4 py-6 mx-auto sm:px-6 lg:max-w-7xl lg:py-8 lg:px-8">
      <div className="relative flex flex-col h-full">
        {showLookupForm ? (
          <>
            <Debbie
              title="Welcome"
              message={`Please enter your ${externalIdLabel.toLowerCase()} below to get started`}
            />
            <LookupAccount
              loading={loading}
              error={getErrorMessage({
                error,
                notFoundError,
                accountCanNotSignup,
              })}
              requiresVerification={requiresVerification}
              onSubmit={values => {
                patch({...values, lookingUpAnother: ''});
              }}
              supportedPaymentCategory={SUPPORTS_PAYMENT_CATEGORY}
            />
          </>
        ) : null}

        {loading ? <Loading /> : null}

        {showConfirmLinking ? (
          <>
            <Debbie title="Is this the property you were looking for?" />
            <ConfirmLinkAccount
              account={account}
              onLinkAccount={onNext}
              onLookupAnotherAccount={() => {
                patch({lookingUpAnother: '1'});
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
