import {
  AbsoluteDate,
  NZBankAccountNumber,
  validatePhoneNumber,
} from 'payble-shared';
import {z} from 'zod';

export const okayResponse = z.object({
  ok: z.literal(true),
});

export const zPhoneNumber = z.string().transform((value, ctx) => {
  const cleanedValue = value.replace(/\s/g, '');
  const result = validatePhoneNumber(cleanedValue);

  if (!result) {
    ctx.addIssue({
      code: 'custom',
      message: 'Phone number is required',
    });
    return z.NEVER;
  }

  if (result instanceof Error) {
    ctx.addIssue({
      code: 'custom',
      message: result.message,
    });
    return z.NEVER;
  }

  return result;
});

export const zNZBankAccountNumber = z.string().transform((value, ctx) => {
  const accountNumber = NZBankAccountNumber.maybeFromJSON(value);
  if (!accountNumber) {
    ctx.addIssue({
      code: 'custom',
      message: 'Invalid bank account number',
    });
    return z.NEVER;
  }
  return accountNumber;
});

export const zAbsoluteDate = z
  .string()
  .transform((value, ctx) => {
    const date = AbsoluteDate.maybeFromISO(value);
    if (!date) {
      ctx.addIssue({
        code: 'invalid_date',
        message: 'Invalid date format',
      });
      return z.NEVER;
    }
    return date;
  })
  .or(z.instanceof(AbsoluteDate));

export const zISODate = z
  .string()
  .transform((value, ctx) => {
    const date = new Date(value);
    if (isNaN(date.getTime()) || value !== date.toISOString()) {
      ctx.addIssue({
        code: 'invalid_date',
        message: 'Invalid date format',
      });
      return z.NEVER;
    }
    return date;
  })
  .or(z.instanceof(Date));

export const zNumberString = z
  .string()
  .transform(value => Number(value))
  .refine(value => !isNaN(value))
  .or(z.number());
