import {z} from 'zod';
import {FormConfig} from '../../types';

export const site = {
  title: 'Site Config',
  configType: 'biller',

  validation: z.object({
    consumerAppHostname: z.string(),
    requirePaymentNotificationConsent: z.boolean(),
    allowNonStaffImports: z.boolean(),
    smsProvider: z.string(),
    region: z.string(),
    timezone: z.string(),
    isLive: z.boolean(),
    remitter: z.string(),
    emailProvider: z.string(),
    accountWhitelist: z
      .array(z.object({externalId: z.string().trim()}))
      .refine(
        v => new Set(v.map(({externalId}) => externalId)).size === v.length,
        {message: 'Ids must be unique'}
      ),
    authMethod: z
      .array(z.enum(['mobile', 'email']))
      .min(1, 'Required at least one auth method'),
  }),

  form: [
    {
      type: 'toggle',
      label: 'Is Live',
      name: 'isLive',
    },
    {
      type: 'text',
      label: 'Consumer App Hostname',
      name: 'consumerAppHostname',
    },
    {
      type: 'toggle',
      label: 'Require payment notification consent',
      name: 'requirePaymentNotificationConsent',
    },
    {
      label: 'Auth method',
      type: 'checkboxGroup',
      name: 'authMethod',
      options: [
        {value: 'mobile', label: 'Mobile'},
        {value: 'email', label: 'E-mail'},
      ],
    },
    {
      type: 'dropdown',
      label: 'SMS Provider',
      name: 'smsProvider',
      options: ['twilio', 'sinch'],
    },
    {
      type: 'dropdown',
      label: 'Email Provider',
      name: 'emailProvider',
      options: ['postmark', 'smtp'],
    },
    {
      type: 'dropdown',
      label: 'Region',
      name: 'region',
      options: ['AU', 'NZ'],
    },
    {
      type: 'text',
      label: 'Timezone',
      name: 'timezone',
    },
    {
      type: 'toggle',
      label: 'Allow Non Staff Imports',
      name: 'allowNonStaffImports',
    },

    {
      label: 'Remitter',
      name: 'remitter',
      type: 'text',
      description:
        'What show up in banks statement for the payee if using BECS. Most banks have restrictions on this field so check documentation.',
    },
    {
      label: 'Account whitelist',
      type: 'list',
      name: 'accountWhitelist',
      fields: [
        {
          label: 'External ID',
          name: 'externalId',
          type: 'text',
        },
      ],
    },
  ],
} satisfies FormConfig;
