import React, {useEffect, useState} from 'react';

import {Debbie} from 'components/organisms/Debbie';
import {ConfirmCode} from './components/ConfirmCode';
import {SendCode} from './components/SendCode';

import storage from 'local-storage-fallback';
import {getBillerFriendlyNameFromSlug, getBillerSlugFromUrl} from 'lib/url';
import {Navbar} from 'features/navigation/Navbar';
import {Footer} from 'features/navigation/Footer';
import {InformationDisclaimer} from 'features/login/components/InformationDisclaimer';
import {getSearchParamValue} from 'lib/navigation/routes';
import {possessive} from 'payble-shared';
import {MaxContainer} from 'payble-ui';
import {AuthMethodProps} from 'lib/auth/Auth';
import {AuthMethodPicker} from './components/AuthMethodPicker';
import {SendEmailCode} from './components/SendEmail';
import {ConfirmEmail} from './components/ConfirmEmail';
import {useConsumerAppConfig} from 'lib/appConfig/ConsumerAppConfig';

export const Login: React.FC = () => {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const billerSlug = getBillerSlugFromUrl();
  const goTo = getSearchParamValue('goTo');

  const {authMethod: billerAuthMethods} = useConsumerAppConfig();
  const [authMethod, setAuthMethod] = useState<AuthMethodProps['authMethod']>(
    billerAuthMethods[0] ?? 'mobile'
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    document.title = 'Payble - Login';
  }, []);

  useEffect(() => {
    // The printer adds a check digit to the end of the externalId for some unknown.
    // This is a fix that needs to be drop in 2024.
    if (billerSlug && billerSlug === 'cessnock-city-council') {
      const href = new URL(document.location.href);
      const externalId = href.searchParams.get('e');

      if (!externalId) {
        return;
      }

      const removeCheckDigitAddedByThePrinter =
        externalId.indexOf('/') !== -1 ? externalId.split('/')[0] : externalId;

      if (removeCheckDigitAddedByThePrinter === externalId) {
        return;
      }

      href.searchParams.set('e', removeCheckDigitAddedByThePrinter);
      history.pushState({}, '', href.toString());
    }
  }, [billerSlug]);

  const onSent = () => {
    setIsOTPSent(true);
  };

  const slug = getBillerSlugFromUrl();
  const friendlyName = getBillerFriendlyNameFromSlug(slug);
  const friendlyAuthName =
    authMethod === 'mobile'
      ? 'mobile number'
      : authMethod === 'email'
        ? 'email address'
        : authMethod;

  let debbieTitle = `Welcome to ${possessive(
    friendlyName
  )} flexible payment experience`;
  let debbieMessage = `In just a few taps you'll be able to easily pay and manage your account. To get started, please enter your ${friendlyAuthName}.`;

  if (window.location.hash === '#session-expired') {
    debbieTitle = 'Your session expired';
    debbieMessage = `For security reasons we keep your session alive for a limited time. Please enter your ${friendlyAuthName} again.`;
  } else if (window.location.hash === '#logged-out') {
    debbieTitle = 'Logout successful';
    debbieMessage = `You are logged out and can safely close your browser. To log back in please enter your ${friendlyAuthName} again.`;
  } else if (storage.getItem('signed-up') !== null) {
    debbieTitle = `Welcome back to ${possessive(
      friendlyName
    )} flexible payment experience`;
    debbieMessage = `To view and manage your payments or setup a flexible payment plan, please enter your ${friendlyAuthName}.`;
  }

  if (slug === 'agl-energy') {
    debbieTitle = "Let's setup your direct debit";
    debbieMessage = `In just a few taps, you'll be able to set up your automatic payments. Please enter your ${friendlyAuthName} below to get started.`;
  }

  if (slug === 'agl-energy') {
    debbieTitle = "Let's setup your direct debit";
    debbieMessage = `In just a few taps, you'll be able to set up your automatic payments. Please enter your ${friendlyAuthName} below to get started.`;
  }

  return (
    <div className="flex flex-col justify-between min-h-screen bg-gray-100">
      <div>
        <Navbar />

        <MaxContainer>
          {!goTo && <Debbie title={debbieTitle} message={debbieMessage} />}
          {billerAuthMethods.length > 1 && (
            <AuthMethodPicker
              billerAuthMethods={billerAuthMethods}
              selectedAuthMethod={authMethod}
              setAuthMethod={setAuthMethod}
              disabled={isOTPSent}
            />
          )}
          {authMethod === 'mobile' && (
            <>
              {!isOTPSent ? (
                <SendCode
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  onSent={onSent}
                />
              ) : (
                <ConfirmCode
                  phoneNumber={phoneNumber}
                  goBack={() => {
                    setIsOTPSent(false);
                    setPhoneNumber('');
                  }}
                />
              )}
            </>
          )}
          {authMethod === 'email' && (
            <>
              {!isOTPSent ? (
                <SendEmailCode
                  email={email}
                  setEmail={setEmail}
                  onSent={onSent}
                />
              ) : (
                <ConfirmEmail
                  email={email}
                  goBack={() => {
                    setIsOTPSent(false);
                    setEmail('');
                  }}
                />
              )}
            </>
          )}
        </MaxContainer>
      </div>

      <InformationDisclaimer />
      <Footer />
    </div>
  );
};
