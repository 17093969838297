import React from 'react';
import {classNames} from 'lib/styles';
import {RadioGroup} from '@headlessui/react';
import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {AuthMethodProps} from 'lib/auth/Auth';

export const AUTH_METHODS: {
  id: string;
  name: string;
  type: AuthMethodProps['authMethod'];
}[] = [
  {
    id: 'mobile-auth-method',
    name: 'Mobile number',
    type: 'mobile',
  },
  {
    id: 'email-auth-method',
    name: 'Email',
    type: 'email',
  },
];

type AuthMethodPickerProps = {
  setAuthMethod: (authMethod: AuthMethodProps['authMethod']) => void;
  disabled?: boolean;
  selectedAuthMethod?: AuthMethodProps['authMethod'];
  billerAuthMethods: AuthMethodProps['authMethod'][];
};

export const AuthMethodPicker: React.FC<AuthMethodPickerProps> = ({
  setAuthMethod,
  disabled,
  selectedAuthMethod,
  billerAuthMethods,
}) => {
  const availableAuthMethods = AUTH_METHODS.filter(({type}) =>
    billerAuthMethods.includes(type)
  );

  return (
    <RadioGroup
      value={selectedAuthMethod}
      onChange={setAuthMethod}
      disabled={disabled}
    >
      <div className="flex flex-col gap-4 mx-auto w-72 mt-8">
        {availableAuthMethods.map(method => (
          <RadioGroup.Option
            key={method.id}
            value={method.type}
            className={({checked, active}) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-blue-500' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex items-center cursor-pointer focus:outline-none'
              )
            }
          >
            {({checked, active}) => (
              <>
                {method.type === 'mobile' && (
                  <div className="flex gap-1 mr-2">
                    <img
                      src="/cms/app-shared/auth/mobile.svg"
                      className={classNames(
                        'h-6',
                        checked ? 'opacity-100' : 'opacity-50'
                      )}
                    />
                  </div>
                )}
                {method.type === 'email' && (
                  <div className="flex gap-1 mr-2">
                    <img
                      src="/cms/app-shared/auth/email.svg"
                      className={classNames(
                        'h-6',
                        checked ? 'opacity-100' : 'opacity-50'
                      )}
                    />
                  </div>
                )}
                <div className="flex flex-1">
                  <div className="flex flex-1">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {method.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-blue-600'
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
