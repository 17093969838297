import {useAPIQuery} from 'lib/api';
import {navigate} from 'lib/navigation/routes';
import {errs} from 'payble-shared';
import {useEffect, useMemo} from 'react';
import _refiner from 'refiner-js';
import {analytics} from 'src/analytics/hooks/useAnalytics';
import {useConsumerAppConfig} from '../appConfig/ConsumerAppConfig';
import {loadSessionData} from './initialisation';
import {clear} from './token';

export const useLoadUser = (enabled: boolean) => {
  const appConfig = useConsumerAppConfig();

  const {
    data: contact,
    isPending: isLoadingContact,
    isFetching: isFetchingContact,
    isSuccess: isSuccessContact,
    isError: isContactError,
  } = useAPIQuery('getSessionContact', {
    query: {
      enabled,
      retry: (tries, error) =>
        !(error instanceof errs.AuthenticationError) && tries < 3,
    },
  });

  const {
    data: instalmentPlans,
    isPending: isLoadingInstalmentPlans,
    isFetching: isFetchingInstalmentPlans,
    isSuccess: isSuccessInstalmentPlans,
    isError: isInstalmentPlansError,
  } = useAPIQuery('getInstalmentPlans', {
    query: {
      enabled,
      retry: (tries, error) =>
        !(error instanceof errs.AuthenticationError) && tries < 3,
    },
  });

  const {
    data: payments,
    isPending: isLoadingPayments,
    isFetching: isFetchingPayments,
    isSuccess: isSuccessPayments,
    isError: isPaymentsError,
  } = useAPIQuery('getPayments', {
    query: {
      enabled,
      retry: (tries, error) =>
        !(error instanceof errs.AuthenticationError) && tries < 3,
    },
  });

  const isLoading =
    isLoadingContact || isLoadingInstalmentPlans || isLoadingPayments;

  const isFetching =
    isFetchingContact || isFetchingInstalmentPlans || isFetchingPayments;

  const isSuccess =
    !isFetching &&
    isSuccessContact &&
    isSuccessInstalmentPlans &&
    isSuccessPayments;

  const isError = isPaymentsError || isInstalmentPlansError || isContactError;

  useEffect(() => {
    if (contact) {
      // @ts-expect-error This is used by e2e tests
      window.contactId = contact.id;

      analytics.reidentify();

      _refiner('identifyUser', {
        id: contact?.id,
        email: contact?.email,
        name: `${contact?.givenName} ${contact?.familyName}`,
        biller_slug: appConfig.biller.slug,
      });
    }
  }, [contact, appConfig]);

  useEffect(() => {
    if (contact && contact.billerId !== appConfig.biller.billerId) {
      clear();
      navigate('/biller/:slug', {slug: appConfig.biller.slug});
    }
  }, [contact, appConfig]);

  useEffect(() => {
    if (isError) {
      clear();
      navigate('/biller/:slug', {slug: appConfig.biller.slug});
    }
  }, [isError]);

  return useMemo(() => {
    const sessionData = loadSessionData();

    return {
      sessionData,
      contact,
      instalmentPlans,
      payments,
      isLoading,
      isFetching,
      isSuccess: isSuccess && !isFetching && !isLoading,
    };
  }, [contact, instalmentPlans, payments, isLoading, isFetching, isSuccess]);
};
