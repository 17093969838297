import {FC} from 'react';

import {inputVariants} from './Input';
import {cn} from '../lib/utils';

export type InputPhoneProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  size?: 'default' | 'sm';
};

const InputPhone: FC<InputPhoneProps> = ({
  value,
  onChange = () => {},
  className,
  placeholder = '0400 000 000',
  disabled,
  size,
}) => {
  return (
    <input
      value={value}
      onChange={onChange}
      type="tel"
      autoFocus={true}
      className={cn(inputVariants({className, size}), 'text-center')}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

InputPhone.displayName = 'InputPhone';

export {InputPhone};
