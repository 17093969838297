import {BillerConfig} from 'payble-shared';
import {ConsumerAppConfig} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {z} from 'zod';

type ConfigStruct = {
  appConfig: ConsumerAppConfig;
  billerConfig: BillerConfig;
};

export type FlowDefinition<
  SD extends Record<string, StepDefinition> = Record<string, StepDefinition>,
> = {
  path: string;
  available?: (config: ConfigStruct) => boolean;
  steps: SD;
};

export type StepDefinition<S extends z.Schema = z.Schema> = {
  schema: (config: ConfigStruct) => S;
  render: (
    state: z.output<NoInfer<S>>,
    config: ConfigStruct
  ) => React.ReactElement | React.ReactNode;
};

export const defineStep = <const S extends z.Schema>(
  {
    schema,
  }: {
    schema: (config: ConfigStruct) => S;
  },
  render: (
    state: z.output<NoInfer<S>>,
    config: ConfigStruct
  ) => React.ReactElement | React.ReactNode
) => {
  return {schema, render};
};

export const defineFlow = <
  const SD extends Record<string, StepDefinition>,
  FD extends FlowDefinition<SD>,
>(
  config: FD
): FD => {
  return config;
};
