import {z} from 'zod';
import {defineFlow, defineStep} from '../FlowDefinition';
import {AccountLookupStep} from './steps/pay-now/AccountLookupStep';
import {useFlowNavigate} from '../flows';
import {PaymentAmountStep} from './steps/pay-now/PaymentAmountStep';
import {PayStep} from './steps/pay-now/payStep';

export const PayNow = defineFlow({
  path: '/pay-now-v2',
  available: ({billerConfig, appConfig}) =>
    !!billerConfig.features.CONTACTLESS_PAYMENTS &&
    appConfig.stage !== 'production',
  steps: {
    'account-lookup': defineStep(
      {
        schema: () => z.object({}),
      },
      () => {
        const next = useFlowNavigate();
        return (
          <AccountLookupStep
            onNext={({accountType, accountExternalId, verificationCode}) => {
              next('PayNow.payment-amount', {
                accountType,
                accountExternalId,
                verificationCode,
              });
            }}
          />
        );
      }
    ),

    'payment-amount': defineStep(
      {
        schema: () =>
          z.object({
            accountType: z.string(),
            accountExternalId: z.string(),
            verificationCode: z.string().optional(),
          }),
      },

      ({accountExternalId, accountType, verificationCode}) => {
        const next = useFlowNavigate();
        return (
          <PaymentAmountStep
            accountExternalId={accountExternalId}
            accountType={accountType}
            verificationCode={verificationCode}
            onNext={({amountInCents}) => {
              next('PayNow.pay', {
                accountType,
                accountExternalId,
                amountInCents,
                verificationCode,
              });
            }}
          />
        );
      }
    ),

    pay: defineStep(
      {
        schema: () =>
          z.object({
            accountType: z.string(),
            accountExternalId: z.string(),
            amountInCents: z.string(),
            verificationCode: z.string().optional(),
          }),
      },

      ({accountExternalId, accountType, verificationCode, amountInCents}) => {
        return (
          <PayStep
            accountExternalId={accountExternalId}
            accountType={accountType}
            verificationCode={verificationCode}
            amountInCents={amountInCents}
          />
        );
      }
    ),
  },
});
