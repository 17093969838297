import React from 'react';
import {Form} from 'payble-ui';
import {z} from 'zod';
import {useAuth} from 'lib/auth/Auth';

type SendCodeProps = {
  email: string;
  setEmail: (email: string) => void;
  onSent: () => void;
};

export const SendEmailCode: React.FC<SendCodeProps> = ({
  email,
  setEmail,
  onSent,
}) => {
  const {sendOTP} = useAuth();

  return (
    <div className="flex flex-col items-center justify-center gap-4 mx-auto w-72">
      <p className="mt-8 text-lg font-bold text-center text-navy">
        Enter your email address
      </p>

      <p className="text-sm text-center text-gray-600 w-72">
        You will receive an email to confirm your identity. By entering your
        email address you consent to Payble contacting you by email with a 6
        digit one time code.
      </p>

      <Form
        className="w-full"
        schema={z.object({
          email: z.string().email(),
        })}
        defaultValues={{
          email,
        }}
        onSubmit={async data => {
          setEmail(data.email);

          const {loginIdentity} = await sendOTP({
            loginIdentity: data.email,
            authMethod: 'email',
          });

          setEmail(loginIdentity);

          onSent();
        }}
      >
        <Form.Input
          type="email"
          name="email"
          placeholder="user@example.com"
          className="text-xl text-center"
        />

        <Form.SubmitButton>Get one time PIN</Form.SubmitButton>
      </Form>
    </div>
  );
};
