import React, {useRef, useEffect} from 'react';
import * as Sentry from '@sentry/react';
import * as actualFlows from './definitions';
import {Route, useLocation} from 'react-router';
import {FlowDefinition, StepDefinition} from './FlowDefinition';
import {BillerConfig} from 'payble-shared';
import {ConsumerAppConfig} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {fromQueryString} from 'payble-api-client';
import {MaxContainer} from 'payble-ui';
import {ErrorPage} from 'features/navigation/Error';

const useRunOncePerRender = (callback: () => void) => {
  const hasRunRef = useRef(false);

  useEffect(() => {
    console.log(hasRunRef);
    if (!hasRunRef.current) {
      callback();
      hasRunRef.current = true;
    }
  });
};

function StepContainer(props: {
  flow: FlowDefinition;
  stepName: string;
  step: StepDefinition;
  config: {
    appConfig: ConsumerAppConfig;
    billerConfig: BillerConfig;
  };
}) {
  const {flow, step, stepName, config} = props;

  const stepData = step
    .schema(config)
    .safeParse(fromQueryString(useLocation().search.substring(1)));

  if (!stepData.success) {
    const invalidStateError = new Error(
      `Invalid state in flow route flow: ${flow.path} step: ${stepName} stepData error: ${JSON.stringify(stepData.error.errors)}`
    );

    useRunOncePerRender(() => {
      Sentry.captureException(invalidStateError);
    });

    return <ErrorPage showNavbar={false} />;
  }

  return <>{step.render(stepData.data, config)}</>;
}

export function getFlowRoutes(config: {
  billerConfig: BillerConfig;
  appConfig: ConsumerAppConfig;
}) {
  const flows: Record<string, FlowDefinition> = actualFlows;

  return Object.entries(flows).flatMap(([flowName, flowDefinition]) => {
    if (flowDefinition.available && !flowDefinition.available(config)) {
      return null;
    }

    return Object.entries(flowDefinition.steps).map(
      ([stepName, stepDefinition]) => {
        return (
          <Route
            key={`${flowName}-${stepName}`}
            path={`/biller/:slug${flowDefinition.path}/${stepName}`}
            element={
              <MaxContainer>
                <StepContainer
                  config={config}
                  flow={flowDefinition}
                  stepName={stepName}
                  step={stepDefinition}
                />
              </MaxContainer>
            }
          ></Route>
        );
      }
    );
  });
}
